.search-bar {
    position: fixed;
    width: 100%;
    top: 52px;
    height: 74px;
    background-color: #fafafa;
    z-index: 2;
}

.milestone-cards-container {
    margin-top: 50px;
}

.book-cards-container {
    margin-top: 50px;
}

.SortableHelper{
    z-index: 2;
}
