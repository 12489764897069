@charset "utf-8";

// @import 'font-awesome';
// @import 'toastr';
// @import "datepicker";
// @import 'fonts';

// Colors
$bgGrey: #EEEEEE;
$regularText: #444444;
$lightGrayText: #7B7B7B;
$babyGreen: #92D2CD;
$babyPlusGreen: #B4D79F;
$babyPink: #F599AA;
$highlightPink: #ec607b;

$fontLight: 300;
$fontBold: 700;

body {
    margin: 0;
}

h1 {
    color: $babyGreen;
}

a {
    text-decoration: none;
}

a.menuListItem > div> div > h3 {
    color: $babyGreen;
}

a.menuListItem > div> svg {
    color: $babyGreen;
}

.full-height {
    height: 100vh;
}

.full-width {
    width: 100vw;
}

.w100-percent {
    width: 100%;
}

.capitalize > span {
    text-transform: capitalize;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper > div {
    display: flex;
}

.login-bg {
  background: url(../images/login_bg.png) no-repeat;
  background-size: cover;
}

ul.react-datepicker__time-list {
    padding: 0;
}


@import '_admin/milestones';
@import '_admin/components';
