.milestone-card-header{
    padding: 5px;
    .box {
        border: 5px;
        border-style: solid;
        border-color: transparent;
        transition: border-color .5s;
        &:hover{
            cursor: pointer;
            border-color: rgb(204, 133, 40);
        }
    }
}
